@import url('https://fonts.googleapis.com/css?family=Inter');
/*@font-face {*/
/*  font-family: 'CustomFont';*/
/*  !*src: url('./assets/fonts/Volvo-Novum-Medium.woff') format('truetype');*!*/
/*  !* url('../public/fonts/OpenSans-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),*!*/
/*  src: url('https://siensnet-php5.siens.com.br/imagens/volvo/Volvo-Novum-Medium.woff') format('woff');*/
/*  !*url('../public/fonts/OpenSans-ExtraBoldItalic.woff') format('woff'),*!*/
/*  !*url('../public/fonts/OpenSans-ExtraBoldItalic.ttf') format('truetype');*!*/
/*}*/

body {
  margin: 0;
  font-family: -apple-system, 'CustomFont', serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
p {
  margin: 0 !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
:root {
  --cor-primaria: #141414;
  --cor-secundaria: #cccccc;
  --cor-terciaria: #d5ebfa;
}
.tooltip-inner {
  background-color: var(--cor-primaria) !important;
}
.tooltip-arrow {
  border-top-color: var(--cor-primaria) !important;
}
.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: var(--cor-primaria) !important;
}
.popover-body {
  background-color: var(--cor-primaria) !important;
  border-top-color: var(--cor-primaria) !important;
  color: white !important;
  border-radius: 4px !important;
}

.popover-arrow::after {
  border-top-color: var(--cor-primaria) !important;
}
